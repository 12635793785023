<div class="error-page">
  <div class="error-page__container">
    <div class="error-page__title">
      <ui-icon class="error-page__icon" icon="error" filled />
      <h2>
        {{
          status() === UserStatus.Inactive
            ? 'Account deactivated'
            : 'Pending activation'
        }}
      </h2>
    </div>

    <p
      class="message"
      [ngClass]="{
        inactive: status() === UserStatus.Inactive,
        pending: status() === UserStatus.Pending
      }"
    >
      @if (status() === UserStatus.Inactive) {
        Your Client Portal account has been deactivated and you no longer have
        access to any clients.
      } @else {
        Your account is pending activation. Please wait and try to log in again.
      }
    </p>
    <p class="countdown">
      You will be logged out in: <strong>{{ countdown() }}</strong> seconds
    </p>
  </div>
</div>

@if (loader()) {
  <ui-progress-spinner />
}
