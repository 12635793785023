import {
  ChangeDetectionStrategy,
  Component,
  signal,
  Signal,
} from '@angular/core';
import { finalize, map, takeWhile, timer } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { ProgressSpinnerComponent } from '@client-portal/ui/progress-spinner';
import { LogOutService } from '@client-portal/auth/services';
import { UserStatus } from '@client-portal/enums/user';
import { NgClass } from '@angular/common';
import { UserService } from '@client-portal/services/user';
import { IconComponent } from '@client-portal/ui/icon';

const COUNTDOWN_FROM = 5;

@Component({
  selector: 'clp-inactive-account',
  standalone: true,
  templateUrl: './inactive-account.component.html',
  styleUrls: [
    './inactive-account.component.scss',
    '../../../../shared/styles/error-page.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ProgressSpinnerComponent, NgClass, IconComponent],
})
export class InactiveAccountComponent {
  protected readonly UserStatus = UserStatus;
  public status: Signal<UserStatus | undefined> = toSignal(
    this._userService.userProfile$.pipe(map((user) => user.status)),
  );

  public loader = signal(false);
  public countdown: Signal<number | undefined> = toSignal(
    timer(0, 1000).pipe(
      map((i) => COUNTDOWN_FROM - i),
      takeWhile((i) => i >= 0),
      finalize(() => {
        this.loader.set(true);
        this._logOutService.logOut();
      }),
    ),
  );

  constructor(
    private _logOutService: LogOutService,
    private _userService: UserService,
  ) {}
}
